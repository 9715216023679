* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  margin: 0;
}

.c-container {
  min-height: 100vh;
  padding: 2rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: stretch;
  margin: 0 auto;
  text-align: center;
}

.c-header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.c-profile {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}

.c-profile__image {
  vertical-align: bottom;
  border-radius: 100%;
  margin-right: 0.5em;
  width: 3em;
}

.c-diagram {
  margin: 0 auto;
  border: 1px solid black;
  width: 750px;
  height: 750px;
}

.o-section {
  margin-bottom: 2em;
}

.c-home {
  max-width: 800px;
  margin: 0 auto;

  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
}

.c-home__section {
  flex-basis: 50%;
  flex-grow: 0;
  flex-shrink: 0;
}

.c-raid-link {
  display: inline-block;
  text-align: left;
}

.c-raid-link__title {
  display: block;
  font-weight: bold;
  font-size: 1.2em;
}

.c-raid-link__author {
  display: block;
}
